const logo = (props) => (
  <svg
    width="49"
    height="41"
    viewBox="0 0 49 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.09258 35.3892C9.52183 39.1006 17.7384 41 24.5 41C31.2616 41 39.4782 39.1006 43.9074 35.3892C48.3367 31.6778 49 26.1763 49 20.5C49 14.8237 48.2725 9.25666 43.8432 5.54526C39.414 1.83387 31.2616 0 24.5 0C17.7384 0 9.58603 1.83387 5.15677 5.54526C0.727511 9.25666 0 14.8456 0 20.5C0 26.1544 0.663319 31.6778 5.09258 35.3892ZM12.1751 12.9899C12.1751 7.33546 17.6956 2.7508 24.5 2.7508C31.3044 2.7508 36.8249 7.33546 36.8249 12.9899V27.7481C36.8249 33.4026 31.3044 37.9872 24.5 37.9872C17.6956 37.9872 12.1751 33.4026 12.1751 27.7481V12.9899Z" />
  </svg>
);
export default logo;
