import React, { useState } from "react";
import { Button, Offcanvas, Row, Col, Image, Container } from "react-bootstrap";
import { Standard, StateOregon, ScissorsCircleNone } from "../assets/images";

function Modifications({
  name,
  customisationData,
  setCustomisationData,
  ...props
}) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  function setModificationStyle(params) {
    let newData = { ...customisationData, modificationStyle: params };
    setCustomisationData(newData);
    handleClose();
  }

  return (
    <>
      <Button
        variant="transparent"
        onClick={handleShow}
        className="border-0 shadow-none p-0"
      >
        {name}
      </Button>
      <Offcanvas
        show={show}
        onHide={handleClose}
        {...props}
        className="bg-vista-white modification-offcanvas"
      >
        <Container fluid>
          <Offcanvas.Header className="justify-content-end">
            <Button
              variant="transparent"
              className="shadow-none border-0 p-0"
              onClick={handleClose}
            >
              <span className="text-black">Modifications</span>{" "}
              <Image src={ScissorsCircleNone} />
            </Button>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Row className="gy-5">
              <Col md={3}></Col>
              <Col
                col={6}
                lg={3}
                className="text-center modifications-img"
                onClick={() => {
                  setModificationStyle(1);
                }}
              >
                <Image
                  src={Standard}
                  className="mb-5"
                  role="button"
                />
                <p className="text-black font-semibold">Standard</p>
              </Col>
              <Col
                col={6}
                lg={3}
                className="text-center"
                onClick={() => {
                  setModificationStyle(2);
                }}
              >
                <Image
                  src={StateOregon}
                  className="mb-5"
                  role="button"
                />
                <p className="text-black font-semibold">State of Oregon Pocket</p>
              </Col>
              <Col md={3}></Col>
            </Row>
          </Offcanvas.Body>
        </Container>
      </Offcanvas>
    </>
  );
}

export default Modifications;
