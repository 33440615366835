import React, { useState } from "react";
import {
  Dropdown,
  Navbar,
  Nav,
  Container,
  Image,
  Button,
  DropdownButton,
} from "react-bootstrap";
import "./style.scss";
import {
  Logo,
  Elephant,
  ScissorsCircleNone,
  LightWithoutCircle,
  InfoIcon,
  About,
} from "../../assets/images/index";
import { FiSend } from "react-icons/fi";
import { AiOutlineMenu } from "react-icons/ai";
import { HalfWallet, Modifications } from "../index";
import {
  ShareOrSaveYourDesign,
  DoneModal,
  SubmitOrder,
  OrderReceived,
  HandcraftedByWoollyMade,
} from "../modal/index";
import Inspiration from "../inspiration";
import { icons } from "react-icons";

function Header({
  customisationData,
  setCustomisationData,
  screenshot,
  takeScreenshot,
}) {
  const [shareModelShow, setShareModelShow] = useState(false);
  const [doneModelShow, setDoneModelShow] = useState(false);
  const [sliderModelShow, setSliderModelShow] = useState(false);
  const [submitModelShow, setSubmitModelShow] = useState(false);
  const [orderReceivedModelShow, setOrderReceivedModelShow] = useState(false);
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="green"
        variant="dark"
        className="navbar-header px-0 px-md-4">
        <Container fluid className="gx-10">
            <Navbar.Brand href="#" className="d-lg-block page-title">
                <Image className="h-auto w-sm-auto w-56" src={Logo} />
            </Navbar.Brand>
            <Navbar.Collapse
                id="header-navbar"
                className="justify-content-center pb-5 pb-lg-0 d-none d-lg-flex"
            >
                <Nav
                    className="flex-row gap-3 align-items-center translate-middle-x position-absolute start-1/2 text-semi-wider text-sm"
                    onClick={() => setSliderModelShow(true)}
                    role="button"
                >
                    <Nav.Link
                        href="#"
                        className="px-0 ls-widest_bold bg-transparent text-sm text-vistaWhite font-max-bold"
                    >
                        HANDCRAFTED
                    </Nav.Link>{" "}
                    <Image src={Elephant} className="w-8 " />{" "}
                    <Nav.Link
                        className="px-0 bg-transparent text-sm ls-widest_bold text-vistaWhite font-max-bold"
                        href="#">
                        WOOLLY MADE
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>
            <span className="d-none d-lg-flex align-items-center me-5">
                <Dropdown className="half-drop-down">
                    <Dropdown.Toggle
                        variant="transparent"
                        className="p-0 text-white card-list-flush d-flex align-items-center font-max-bold"
                    >
                    Half Wallet
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="py-0 rounded-1 bg-linen wallet-dropdown text-center">
                        <Dropdown.Item href="#" className="text-base text-dark-grey font-max-bold">
                            Half Wallet
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                {/* <Nav>
                <Nav.Link
                    href="#"
                    className="p-0 mx-5 bg-transparent"
                    onClick={() => {
                    setShareModelShow(true);
                    takeScreenshot();
                    }}
                >
                    <FiSend className="text-xl" />{" "}
                </Nav.Link>
                </Nav> */}
            </span>
            <div className="d-block desktop-navbar">
                <DropdownButton
                autoClose={true}
                variant="transparent p-0"
                title=<AiOutlineMenu className="text-2xl text-white" />
                className="dropdown-menu-end">
                    <Dropdown className="half-drop-down d-lg-none">
                        <Dropdown.Toggle
                            variant="transparent"
                            className="text-black card-list-flush mb-4 pb-0"
                            >
                            Half Wallet
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="mx-4">
                            <Dropdown.Item href="#" className="text-base text-dark-grey">
                                Half Wallet
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown eventKey="2">
                        <Nav.Link
                            href="#"
                            className="text-dark bg-transparent py-0 px-4 mb-4 font-regular"
                            onClick={() => {
                                setShareModelShow(true);
                                takeScreenshot();
                            }}
                        >
                            <span className="text-black font-regular text-base d-flex align-items-center">
                                <FiSend className="w-5 h-5 me-2" />
                                <p className="text-black text-sm font-semibold">
                                    {" "}
                                    Share Design{" "}
                                </p>{" "}
                            </span>
                        </Nav.Link>
                    </Dropdown>
                    {/* <Dropdown eventKey="4" className=" d-block">
                        <div className="mb-4 px-4 font-regular">
                            <Modifications
                                key={0}
                                placement={"bottom"}
                                name={
                                <span className="text-black font-regular text-base">
                                    <Image src={ScissorsCircleNone} /> Modifications{" "}
                                </span>
                                }
                                customisationData={customisationData}
                                setCustomisationData={setCustomisationData}
                            />
                        </div>
                    </Dropdown> */}
                    {/* <Dropdown>
                        <div className="mb-4 px-4 font-regular">
                        <Inspiration
                            key={0}
                            placement={"bottom"}
                            name={
                            <span className="text-black font-regular text-base">
                                <Image src={LightWithoutCircle} /> Inspiration{" "}
                            </span>
                            }
                        />
                        </div>
                    </Dropdown> */}
                    <Dropdown eventKey="5" className="py-0 dropdown-item d-block">
                        <p className="text-base text-black font-regular">
                            <div className="font-regular">
                                <HalfWallet
                                key={0}
                                placement={"bottom"}
                                name={
                                    <span className="text-black font-regular text-base d-flex align-items-center">
                                    <Image src={InfoIcon} className="w-5 h-4 me-2" />
                                    {/* <TiInfoLarge  className="text-2xl"/> */}
                                       <p className="text-sm lh-normal font-semibold mt-1">Product Details</p>
                                    </span>
                                }
                                />
                            </div>
                        </p>
                    </Dropdown>
                    <Dropdown eventKey="5" className="py-0 dropdown-item d-block d-lg-none">
                        <Nav
                            className=""
                            onClick={() => setSliderModelShow(true)}
                            role="button"
                        >
                            <p
                                href="#"
                                className="text-base text-black mb-4 font-regular"
                            >
                            <Image src={About} className="me-2" />
                                About Us
                            </p>{" "}
                        </Nav>
                    </Dropdown>
                    <Dropdown eventKey="3" className="d-block d-lg-none">
                        <Button
                            variant="outline-black mx-4 mb-4 font-regular done-btn"
                            onClick={() => {
                                setDoneModelShow(true);
                                takeScreenshot();
                            }}
                        >
                            Done
                        </Button>
                    </Dropdown>
                </DropdownButton>
            </div>
          {/* <Button
            variant="outline-white"
            className="d-none d-lg-flex done-btn"
            onClick={() => {
              setDoneModelShow(true);
              takeScreenshot();
            }}
          >
            Done
          </Button> */}
        </Container>
      </Navbar>

      <ShareOrSaveYourDesign
        show={shareModelShow}
        setShow={setShareModelShow}
        screenshot={screenshot}
        takeScreenshot={takeScreenshot}
      />
      <DoneModal
        show={doneModelShow}
        setShow={setDoneModelShow}
        setSubmitModelShow={setSubmitModelShow}
        setShareModelShow={setShareModelShow}
        screenshot={screenshot}
      />
      <SubmitOrder
        show={submitModelShow}
        setShow={setSubmitModelShow}
        setOrderReceivedModelShow={setOrderReceivedModelShow}
        screenshot={screenshot}
      />
      <OrderReceived
        show={orderReceivedModelShow}
        setShow={setOrderReceivedModelShow}
        screenshot={screenshot}
      />
      <HandcraftedByWoollyMade
        show={sliderModelShow}
        setShow={setSliderModelShow}
      />
    </>
  );
}

export default Header;
