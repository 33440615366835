import React from "react";
import { Image, Modal, Row, Form, Col, Button } from "react-bootstrap";
import { GrFormClose } from "react-icons/gr";

function OrderReceived(props) {
  // const [setShow] = useState(false);
  // const handleClose = () => setShow(false);
  const show = props.show;
  const handleClose = () => props.setShow(false);
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="ShareOrSaveYourDesign"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className="border-0 pe-0 bg-rose-white">
          <Button
            variant="transparent"
            className="shadow-none border-0 p-0"
            onClick={handleClose}
          >
            {" "}
            <GrFormClose className="text-2xl" />{" "}
            <span className="text-black">Close</span>{" "}
          </Button>
        </Modal.Header>
        <Modal.Title
          id="ShareOrSaveYourDesign"
          className="text-center w-full bg-rose-white"
        >
          <h3 className="text-uppercase text-hochelaga">Submit Order</h3>
        </Modal.Title>
        <Modal.Body className="py-sm-12 px-sm-20 bg-rose-white">
          <div className="submit-sec">
            <Form>
              <Row className="align-items-center gy-10">
                <Col lg={6}>
                  <div className="text-center">
                    {/* <Image src={props.screenshot} className="w-48" /> */}
                    <div className="img-box">
                      <Image src={props.screenshot} className="img-cover" />
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <h2 className="text-black mb-5">Order received!</h2>
                  <p className="text-black">
                    You will receive an email from a member of our team
                    regarding the status of your order{" "}
                    <b>within 1 business day.</b>
                  </p>
                </Col>
              </Row>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default OrderReceived;
