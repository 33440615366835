import React from "react";
import { Button, Image, Modal, Row, Form, Col } from "react-bootstrap";
import { GrFormClose } from "react-icons/gr";

function SubmitOrder(props) {
  // const [setShow] = useState(false);
  // const handleClose = () => setShow(false);
  const show = props.show;
  const handleClose = () => props.setShow(false);
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="ShareOrSaveYourDesign"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className="border-0 pe-0 bg-rose-white">
          <Button
            variant="transparent"
            className="shadow-none border-0 p-0"
            onClick={handleClose}
          >
            {" "}
            <GrFormClose className="text-2xl" />{" "}
            <span className="text-black">Close</span>{" "}
          </Button>
        </Modal.Header>
        <Modal.Title
          id="ShareOrSaveYourDesign"
          className="text-center w-full bg-rose-white"
        >
          <h3 className="text-uppercase text-hochelaga">Submit Order</h3>
          <p>All we need is your name and email address.</p>
        </Modal.Title>
        <Modal.Body className="py-sm-12 px-sm-20 bg-rose-white">
          <div className="submit-sec">
            <Form>
              <Row className="align-items-center gy-10">
                <Col lg={6}>
                  <div className="text-center">
                    {/* <Image src={props.screenshot} className="w-48" /> */}
                    <div className="img-box">
                      <Image src={props.screenshot} className="img-cover" />
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="pb-5">
                    <Form.Group className="mb-3">
                      <Form.Control
                        className="input-border shadow-none"
                        type="text"
                        placeholder="Full Name"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Control
                        className="input-border shadow-none"
                        type="email"
                        placeholder="Email Address"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Control
                        className="input-border shadow-none"
                        type="text"
                        placeholder="Order Code"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Control
                        className="input-border shadow-none"
                        as="textarea"
                        rows={3}
                        placeholder="Shipping Address"
                      />
                    </Form.Group>
                  </div>
                  <Button
                    variant="green"
                    className="rounded-6 px-sm-12 px-5"
                    onClick={() => {
                      props.setOrderReceivedModelShow(true);
                      handleClose();
                    }}
                  >
                    Submit Order
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SubmitOrder;
