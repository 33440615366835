import React from "react";
import { Tab, Nav, Image } from "react-bootstrap";
import { leatherColor, leatherPattern, steps, threadColor } from "../data";

function ColorPatternTab({
  stepIndex,
  setColor,
  setPattern,
  customisationData,
}) {
  return (
    <div className="color-pattern-tab d-block d-lg-none">
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Nav variant="pills" className="d-flex justify-content-center pb-5">
          <Nav.Item>
            <Nav.Link eventKey="first" className="border-left-radius px-10">
              leather
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="second" className="border-right-radius px-10">
              Pattern
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="first" className="overflow-scroll">
            <div className="left-color d-flex gap-5 d-lg-none justify-content-center pb-14 overflow-scroll">
              {stepIndex !== 8 &&
                stepIndex !== 9 &&
                stepIndex !== 7 &&
                leatherColor.map((value, index) => {
                  return (
                    <button
                      key={index}
                      title={value.colorName}
                      className={
                        customisationData[steps[stepIndex - 1].stepCodeName]
                          .color === value.colorHexcode
                          ? "color-round rounded-circle position-relative animate active"
                          : "color-round rounded-circle position-relative animate"
                      } // add "active" when selected
                      style={{ backgroundColor: value.colorHexcode }}
                      onClick={() => setColor(value.colorHexcode)}
                    >
                      <span className="color-name text-black text-sm font-semibold">
                        Olive
                      </span>
                    </button>
                  );
                })}
              {stepIndex === 7 &&
                threadColor.map((value, index) => {
                  return (
                    <button
                      key={index}
                      title={value.colorName}
                      className={
                        customisationData[steps[stepIndex - 1].stepCodeName]
                          .color === value.colorHexcode
                          ? "color-round rounded-circle position-relative animate active"
                          : "color-round rounded-circle position-relative animate"
                      } // add "active" when selected
                      style={{ backgroundColor: value.displayColorCode }}
                      onClick={() => setColor(value.colorHexcode)}
                    >
                      <span className="color-name text-black text-sm font-semibold">
                        Olive
                      </span>
                    </button>
                  );
                })}
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="second">
            <div className="right-pattern d-flex gap-5 d-lg-none justify-content-center pb-14">
              {(stepIndex === 1 || stepIndex === 4) &&
                leatherPattern.map((value, index) => {
                  return (
                    <button
                      key={index}
                      className={
                        customisationData[steps[stepIndex - 1].stepCodeName]
                          .pattern === value.patternCode
                          ? "pattern-round rounded-circle position-relative animate active"
                          : "pattern-round rounded-circle position-relative animate"
                      } // add "active" when selected
                      style={{ backgroundColor: "#F5F0E7" }}
                      onClick={() => setPattern(value.patternCode)}
                    >
                      <Image src={value.patternThumbnail} />
                      <span className="pattern-name text-black text-sm font-semibold">
                        {value.patternName}
                      </span>
                    </button>
                  );
                })}
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
}

export default ColorPatternTab;
