import React from "react";
import { Button, Image, Modal, Row, Form, Col } from "react-bootstrap";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { FiSend } from "react-icons/fi";
import { RxExit } from "react-icons/rx";
import { GrFormClose } from "react-icons/gr";

function DoneModal(props) {
  // const [setShow] = useState(false);
  // const handleClose = () => setShow(false);
  const show = props.show;
  const handleClose = () => props.setShow(false);
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="ShareOrSaveYourDesign"
        centered
        show={show}
        onHide={handleClose}
        className=""
      >
        <Modal.Header className="border-0 pe-0 bg-rose-white">
          <Button
            variant="transparent"
            className="shadow-none border-0 p-0"
            onClick={handleClose}
          >
            {" "}
            <GrFormClose className="text-2xl" />{" "}
            <span className="text-black">Close</span>{" "}
          </Button>
        </Modal.Header>
        <Modal.Title
          id="ShareOrSaveYourDesign"
          className="text-center w-full bg-rose-white"
        >
          <h3 className="text-uppercase text-hochelaga">Done</h3>
        </Modal.Title>
        <Modal.Body className="py-sm-12 px-sm-20 bg-rose-white">
          <Form className="text-center">
            <Row className="align-items-center">
              <Col lg={6}>
                <div>
                  {/* <Image src={props.screenshot} className="w-48" /> */}
                  <div className="img-box h-80">
                    <Image src={props.screenshot} className="img-cover" />
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <Button
                  variant="fill-green"
                  className="shadow-none rounded-6 w-full py-5 text-start d-flex align-items-center"
                  onClick={() => {
                    props.setSubmitModelShow(true);
                    handleClose();
                  }}
                >
                  <AiOutlineShoppingCart className="text-xl me-4" /> Submit
                  Order
                </Button>
                <Button
                  variant="fill-green"
                  className="shadow-none rounded-6 w-full py-5 text-start d-flex align-items-center"
                  onClick={() => {
                    props.setShareModelShow(true);
                    handleClose();
                  }}
                >
                  <FiSend className="text-xl me-4" /> Save or Share Design
                </Button>
                <Button
                  variant="fill-green"
                  className="shadow-none rounded-6 w-full py-5 text-start d-flex align-items-center"
                  onClick={() => window.location.reload()}
                >
                  <RxExit className="text-xl me-4" /> Exit Customizer
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DoneModal;
