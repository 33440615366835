import React, { useState } from "react";
import { Button, Offcanvas, Row, Col, Image } from "react-bootstrap";
import { HalfWalletImg, InfoIcon } from "../assets/images";

function HalfWallet({
  name,
  customisationData,
  setCustomisationData,
  ...props
}) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  function setModificationStyle(params) {
    let newData = { ...customisationData, modificationStyle: params };
    setCustomisationData(newData);
    handleClose();
  }

  return (
    <>
      <Button
        variant="transparent"
        onClick={handleShow}
        className="border-0 shadow-none p-0"
      >
        {name}
      </Button>
      <Offcanvas
        show={show}
        onHide={handleClose}
        {...props}
        className="bg-vista-white modification-offcanvas"
      >
        <Offcanvas.Header className="justify-content-start">
          <Button
            variant="transparent"
            className="shadow-none border-0 p-0"
            onClick={handleClose}
          >
            <Image
              src={InfoIcon}
              className="info-icon me-2 text-black text-xl"
            />
            <span className="text-black">Half Wallet</span>{" "}
            <div className="text-start text-black pt-2">$75.00</div>
          </Button>
        </Offcanvas.Header>
        <Offcanvas.Body className="scroll-hide">
          <Row className="gy-5 justify-content-center  align-items-center">
            <Col
              xl={5}
              md={6}
              col={12}
              className="d-flex justify-content-center align-items-center"
              onClick={() => {
                setModificationStyle(1);
              }}
            >
              <Image src={HalfWalletImg} className="mb-5" role="button" />
            </Col>
            <Col xl={5}>
              <div className="d-flex justify-content-center align-items-center">
                <div>
                  <h1 className="text-2xl">The Half Wallet</h1>
                  <ul className="text-sm text-black">
                    <li>Our classic cardholder and most popular wallet</li>
                    <li>5 pockets hold 6-12 cards and folded cash</li>
                    <li>Measures 4" x 3"</li>
                    <li>Premium U.S. vegetable-tanned leather, undyed</li>
                    <li>Handmade in Portland, OR USA</li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default HalfWallet;
