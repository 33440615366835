import { Pattern, Pattern1 } from "../assets/images/index";
import {
  SliderLogo,
  SliderLogo1,
  SliderLogo2,
  theOLogo,
  ducksLogo,
  mascotLogo,
} from "../assets/images/index";
import {
  InspirationDesign1,
  InspirationDesign2,
  InspirationDesign3,
  InspirationDesign4,
} from "../assets/images/index";
import {
  Inscription,
  Leather,
  Logo,
  Patternsvg,
  Thread,
} from "../assets/images/svg";
export const staticCustomisationData = {
  front1: {
    color: "#ed8d5d",
    pattern: 1,
  },
  front2: {
    color: "#ed8d5d",
    pattern: 1,
  },
  front3: {
    color: "#ed8d5d",
    pattern: 1,
  },
  back1: {
    color: "#ed8d5d",
    pattern: 1,
  },
  back2: {
    color: "#ed8d5d",
    pattern: 1,
  },
  back3: {
    color: "#ed8d5d",
    pattern: 1,
  },
  thread: {
    color: "#FFF0BB",
  },
  branding: 2,
  logo: {
    logoId: 2,
    height: 19 * 1.7,
    width: 121 * 1.7,
    name: "Ducks",
    src: SliderLogo1,
  },
  inscription: "",
  modificationStyle: 1,
};
export const newCustomisationData = {
  // leather: [
  //   { color: "#ed8d5d" }, //"front1"
  //   { color: "#ed8d5d" }, //"front2"
  //   { color: "#ed8d5d" }, //"front3"
  //   { color: "#ed8d5d" }, //"back1"
  //   { color: "#ed8d5d" }, //"back2"
  //   { color: "#ed8d5d" }, //"back3"
  // ],
  leather: {
    front1: { color: "#ed8d5d" },
    front2: { color: "#ed8d5d" },
    front3: { color: "#ed8d5d" },
    back1: { color: "#ed8d5d" },
    back2: { color: "#ed8d5d" },
    back3: { color: "#ed8d5d" },
  },
  pattern: {
    front1: { pattern: 1 },
    front2: { pattern: 1 },
    front3: { pattern: 1 },
    back1: { pattern: 1 },
    back2: { pattern: 1 },
    back3: { pattern: 1 },
  },
  thread: {
    color: "#ed8d5d",
  },
  logo: {
    logoId: 2,
    height: 19 * 1.7,
    width: 121 * 1.7,
    name: "Ducks",
    src: SliderLogo1,
  },
  inscription: "",
  modificationStyle: 1,
  // {
  //   color: "#ed8d5d",
  //   pattern: 1,
  // },
  // pattern: {
  //   color: "#ed8d5d",
  //   pattern: 1,
  // },
  // thread: {
  //   color: "#FFF0BB",
  // },
  // branding: 2,
  // logo: {
  //   logoId: 2,
  //   height: 19 * 1.7,
  //   width: 121 * 1.7,
  //   name: "Ducks",
  //   src: SliderLogo1,
  // },
  // inscription: "",
  // modificationStyle: 1,
};
// export const step = [{step_id:}{}]
export const leatherColor = [
  // { colorName: "BrownSugar", colorHexcode: "#E7AC79" },
  // { colorName: "Goldenrod", colorHexcode: "#F0CC7C" },
  // { colorName: "Olive", colorHexcode: "#7A7A66" },
  // { colorName: "BrightGrey", colorHexcode: "#46454C" },
  { colorName: "Tan", colorHexcode: "#ed8d5d" },
  { colorName: "Olive", colorHexcode: "#6f6550" },
  { colorName: "Yellow", colorHexcode: "#f3bf33" },
  { colorName: "Black", colorHexcode: "#444247" },
];
export const leatherPattern = [
  {
    patternName: "None",
    PatternName: "None",
    patternCode: 1,
    patternThumbnail: null,
  },
  {
    patternName: "Small Perf",
    PatternName: "Small Perf",
    patternCode: 2,
    patternThumbnail: Pattern,
  },
  {
    patternName: "Oregon",
    PatternName: "Oregon",
    patternCode: 3,
    patternThumbnail: Pattern1,
  },
];
export const threadColor = [
  {
    colorName: "Natural",
    colorHexcode: "#FFF0BB",
    displayColorCode: "#E5DFD1",
  },
  { colorName: "Gray", colorHexcode: "#dbdbdb", displayColorCode: "#dbdbdb" },
  { colorName: "Yellow", colorHexcode: "#ffdb79", displayColorCode: "#ffdb79" },
  { colorName: "Neon", colorHexcode: "#60FF00", displayColorCode: "#60FF00" },
  { colorName: "Olive", colorHexcode: "#777364", displayColorCode: "#777364" },
  { colorName: "Brown", colorHexcode: "#a76b57", displayColorCode: "#a76b57" },
  { colorName: "Black", colorHexcode: "#192526", displayColorCode: "#192526" },
];
export const steps = [
  { stepId: 1, stepName: "Front 1", stepCodeName: "front1" },
  { stepId: 2, stepName: "Front 2", stepCodeName: "front2" },
  { stepId: 3, stepName: "Front 3", stepCodeName: "front3" },
  { stepId: 4, stepName: "Back 1", stepCodeName: "back1" },
  { stepId: 5, stepName: "Back 2", stepCodeName: "back2" },
  { stepId: 6, stepName: "Back 3", stepCodeName: "back3" },
  { stepId: 7, stepName: "Thread", stepCodeName: "thread" },
  { stepId: 8, stepName: "Logo", stepCodeName: "logo" },
  { stepId: 9, stepName: "Inscription", stepCodeName: "inscription" },
];
export const newSteps = [
  {
    stepId: 1,
    stepName: "Leather",
    stepCodeName: "leather",
    logo: <Leather />,
    subSteps: [
      {
        subStepId: 1,
        subStepName: "Front 1",
        subStepCodeName: "front1",
        collection_data: [
          { colorName: "Tan", colorHexcode: "#ed8d5d" },
          { colorName: "Olive", colorHexcode: "#6f6550" },
          { colorName: "Yellow", colorHexcode: "#f3bf33" },
          { colorName: "Black", colorHexcode: "#444247" },
        ],
      },
      {
        subStepId: 2,
        subStepName: "Front 2",
        subStepCodeName: "front2",
        collection_data: [
          { colorName: "Tan", colorHexcode: "#ed8d5d" },
          { colorName: "Olive", colorHexcode: "#6f6550" },
          { colorName: "Yellow", colorHexcode: "#f3bf33" },
          { colorName: "Black", colorHexcode: "#444247" },
        ],
      },
      {
        subStepId: 3,
        subStepName: "Front 3",
        subStepCodeName: "front3",
        collection_data: [
          { colorName: "Tan", colorHexcode: "#ed8d5d" },
          { colorName: "Olive", colorHexcode: "#6f6550" },
          { colorName: "Yellow", colorHexcode: "#f3bf33" },
          { colorName: "Black", colorHexcode: "#444247" },
        ],
      },
      {
        subStepId: 4,
        subStepName: "Back 1",
        subStepCodeName: "back1",
        collection_data: [
          { colorName: "Tan", colorHexcode: "#ed8d5d" },
          { colorName: "Olive", colorHexcode: "#6f6550" },
          { colorName: "Yellow", colorHexcode: "#f3bf33" },
          { colorName: "Black", colorHexcode: "#444247" },
        ],
      },
      {
        subStepId: 5,
        subStepName: "Back 2",
        subStepCodeName: "back2",
        collection_data: [
          { colorName: "Tan", colorHexcode: "#ed8d5d" },
          { colorName: "Olive", colorHexcode: "#6f6550" },
          { colorName: "Yellow", colorHexcode: "#f3bf33" },
          { colorName: "Black", colorHexcode: "#444247" },
        ],
      },
      {
        subStepId: 6,
        subStepName: "Back 3",
        subStepCodeName: "back3",
        collection_data: [
          { colorName: "Tan", colorHexcode: "#ed8d5d" },
          { colorName: "Olive", colorHexcode: "#6f6550" },
          { colorName: "Yellow", colorHexcode: "#f3bf33" },
          { colorName: "Black", colorHexcode: "#444247" },
        ],
      },
    ],
  },
  {
    stepId: 2,
    stepName: "Pattern",
    stepCodeName: "pattern",
    logo: <Patternsvg />,
    subSteps: [
      {
        subStepId: 1,
        subStepName: "Front 1",
        subStepCodeName: "front1",
        disable: false,
        collection_data: [
          {
            patternName: "None",
            PatternName: "None",
            patternCode: 1,
            patternThumbnail: null,
          },
          {
            patternName: "Small Perf",
            PatternName: "Small Perf",
            patternCode: 2,
            patternThumbnail: Pattern,
          },
          {
            patternName: "Oregon",
            PatternName: "Oregon",
            patternCode: 3,
            patternThumbnail: Pattern1,
          },
        ],
      },
      {
        subStepId: 2,
        subStepName: "Front 2",
        subStepCodeName: "front2",
        disable: true,
        collection_data: [
          {
            patternName: "None",
            PatternName: "None",
            patternCode: 1,
            patternThumbnail: null,
          },
          {
            patternName: "Small Perf",
            PatternName: "Small Perf",
            patternCode: 2,
            patternThumbnail: Pattern,
          },
          {
            patternName: "Oregon",
            PatternName: "Oregon",
            patternCode: 3,
            patternThumbnail: Pattern1,
          },
        ],
      },
      {
        subStepId: 2,
        subStepName: "Front 3",
        subStepCodeName: "front3",
        disable: true,
        collection_data: [
          {
            patternName: "None",
            PatternName: "None",
            patternCode: 1,
            patternThumbnail: null,
          },
          {
            patternName: "Small Perf",
            PatternName: "Small Perf",
            patternCode: 2,
            patternThumbnail: Pattern,
          },
          {
            patternName: "Oregon",
            PatternName: "Oregon",
            patternCode: 3,
            patternThumbnail: Pattern1,
          },
        ],
      },
      {
        subStepId: 4,
        subStepName: "Back 1",
        subStepCodeName: "back1",
        disable: false,
        collection_data: [
          {
            patternName: "None",
            PatternName: "None",
            patternCode: 1,
            patternThumbnail: null,
          },
          {
            patternName: "Small Perf",
            PatternName: "Small Perf",
            patternCode: 2,
            patternThumbnail: Pattern,
          },
          {
            patternName: "Oregon",
            PatternName: "Oregon",
            patternCode: 3,
            patternThumbnail: Pattern1,
          },
        ],
      },
      {
        subStepId: 5,
        subStepName: "Back 2",
        subStepCodeName: "back2",
        disable: true,
        collection_data: [
          {
            patternName: "None",
            PatternName: "None",
            patternCode: 1,
            patternThumbnail: null,
          },
          {
            patternName: "Small Perf",
            PatternName: "Small Perf",
            patternCode: 2,
            patternThumbnail: Pattern,
          },
          {
            patternName: "Oregon",
            PatternName: "Oregon",
            patternCode: 3,
            patternThumbnail: Pattern1,
          },
        ],
      },
      {
        subStepId: 6,
        subStepName: "Back 2",
        subStepCodeName: "back2",
        disable: true,
        collection_data: [
          {
            patternName: "None",
            PatternName: "None",
            patternCode: 1,
            patternThumbnail: null,
          },
          {
            patternName: "Small Perf",
            PatternName: "Small Perf",
            patternCode: 2,
            patternThumbnail: Pattern,
          },
          {
            patternName: "Oregon",
            PatternName: "Oregon",
            patternCode: 3,
            patternThumbnail: Pattern1,
          },
        ],
      },
    ],
  },
  {
    stepId: 3,
    stepName: "Thread",
    stepCodeName: "thread",
    logo: <Thread />,
    subSteps: [
      {
        subStepId: 7,
        subStepName: "Thread",
        subStepCodeName: "thread",
        collection_data: [
          {
            colorName: "Natural",
            colorHexcode: "#FFF0BB",
            displayColorCode: "#E5DFD1",
          },
          {
            colorName: "Gray",
            colorHexcode: "#dbdbdb",
            displayColorCode: "#dbdbdb",
          },
          {
            colorName: "Yellow",
            colorHexcode: "#ffdb79",
            displayColorCode: "#ffdb79",
          },
          {
            colorName: "Neon",
            colorHexcode: "#60FF00",
            displayColorCode: "#60FF00",
          },
          {
            colorName: "Olive",
            colorHexcode: "#777364",
            displayColorCode: "#777364",
          },
          {
            colorName: "Brown",
            colorHexcode: "#a76b57",
            displayColorCode: "#a76b57",
          },
          {
            colorName: "Black",
            colorHexcode: "#192526",
            displayColorCode: "#192526",
          },
        ],
      },
    ],
  },
  {
    stepId: 4,
    stepName: "Logo",
    stepCodeName: "logo",
    logo: <Logo />,
    subSteps: [
      {
        subStepId: 8,
        subStepName: "Logo",
        subStepCodeName: "logo",
        collection_data: [
          {
            logoId: 1,
            logoName: "The O",
            logoThumbnail: SliderLogo,
            logo: theOLogo,
            height: 52 * 2,
            width: 64 * 2,
          },
          {
            logoId: 2,
            logoName: "Ducks",
            logoThumbnail: SliderLogo1,
            logo: ducksLogo,
            height: 19 * 1.7,
            width: 121 * 1.7,
          },
          {
            logoId: 3,
            logoName: "Mascot",
            logoThumbnail: SliderLogo2,
            logo: mascotLogo,
            height: 56 * 2,
            width: 85 * 2,
          },
        ],
      },
    ],
  },
  {
    stepId: 5,
    stepName: "Inscription",
    stepCodeName: "inscription",
    logo: <Inscription />,
    subSteps: [
      {
        subStepId: 9,
        subStepName: "Inscription",
        subStepCodeName: "inscription",
        collection_data: [],
      },
    ],
  },
];
export const logos = [
  {
    logoId: 1,
    logoName: "The O",
    logoThumbnail: SliderLogo,
    logo: theOLogo,
    height: 52 * 2,
    width: 64 * 2,
  },
  {
    logoId: 2,
    logoName: "Ducks",
    logoThumbnail: SliderLogo1,
    logo: ducksLogo,
    height: 19 * 1.7,
    width: 121 * 1.7,
  },
  {
    logoId: 3,
    logoName: "Mascot",
    logoThumbnail: SliderLogo2,
    logo: mascotLogo,
    height: 56 * 2,
    width: 85 * 2,
  },
];
export const inspirationDesigns = [
  {
    front1: {
      color: "#6f6550",
      pattern: 2,
    },
    front2: {
      color: "#444247",
      pattern: 1,
    },
    front3: {
      color: "#f3bf33",
      pattern: 1,
    },
    back1: {
      color: "#6f6550",
      pattern: 2,
    },
    back2: {
      color: "#444247",
      pattern: 1,
    },
    back3: {
      color: "#f3bf33",
      pattern: 1,
    },
    thread: {
      color: "#ffdb79",
    },
    branding: 2,
    logo: {
      logoId: 1,
      height: 52 * 2,
      width: 64 * 2,
      name: "The O",
      src: SliderLogo,
    },
    inscription: "",
    modificationStyle: 2,
    thumbnail: InspirationDesign1,
  },
  {
    front1: {
      color: "#f3bf33",
      pattern: 1,
    },
    front2: {
      color: "#f3bf33",
      pattern: 1,
    },
    front3: {
      color: "#f3bf33",
      pattern: 1,
    },
    back1: {
      color: "#f3bf33",
      pattern: 1,
    },
    back2: {
      color: "#f3bf33",
      pattern: 1,
    },
    back3: {
      color: "#f3bf33",
      pattern: 1,
    },
    thread: {
      color: "#192526",
    },
    branding: 2,
    logo: {
      logoId: 3,
      height: 56 * 2,
      width: 85 * 2,
      name: "Mascot",
      src: SliderLogo2,
    },
    inscription: "",
    modificationStyle: 2,
    thumbnail: InspirationDesign2,
  },
  {
    front1: {
      color: "#ed8d5d",
      pattern: 1,
    },
    front2: {
      color: "#6f6550",
      pattern: 1,
    },
    front3: {
      color: "#6f6550",
      pattern: 1,
    },
    back1: {
      color: "#ed8d5d",
      pattern: 1,
    },
    back2: {
      color: "#6f6550",
      pattern: 1,
    },
    back3: {
      color: "#6f6550",
      pattern: 1,
    },
    thread: {
      color: "#777364",
    },
    branding: 2,
    logo: {
      logoId: 1,
      height: 52 * 2,
      width: 64 * 2,
      name: "The O",
      src: SliderLogo,
    },
    inscription: "",
    modificationStyle: 1,
    thumbnail: InspirationDesign3,
  },
  {
    front1: {
      color: "#444247",
      pattern: 1,
    },
    front2: {
      color: "#444247",
      pattern: 1,
    },
    front3: {
      color: "#444247",
      pattern: 1,
    },
    back1: {
      color: "#444247",
      pattern: 1,
    },
    back2: {
      color: "#444247",
      pattern: 1,
    },
    back3: {
      color: "#444247",
      pattern: 1,
    },
    thread: {
      color: "#60FF00",
    },
    branding: 2,
    logo: {
      logoId: 2,
      height: 19 * 1.7,
      width: 121 * 1.7,
      name: "Ducks",
      src: SliderLogo1,
    },
    inscription: "",
    modificationStyle: 2,
    thumbnail: InspirationDesign4,
  },
];

// new inspiration designs
export const newinspirationDesigns = [
  {
    leather: {
      front1: { color: "#6f6550" },
      front2: { color: "#444247" },
      front3: { color: "#f3bf33" },
      back1: { color: "#6f6550" },
      back2: { color: "#444247" },
      back3: { color: "#f3bf33" },
    },
    pattern: {
      front1: { pattern: 2 },
      front2: { pattern: 1 },
      front3: { pattern: 1 },
      back1: { pattern: 2 },
      back2: { pattern: 1 },
      back3: { pattern: 1 },
    },
    thread: {
      color: "#ffdb79",
    },
    logo: {
      logoId: 1,
      height: 52 * 2,
      width: 64 * 2,
      name: "The O",
      src: SliderLogo,
    },
    inscription: "",
    modificationStyle: 2,
    thumbnail: InspirationDesign1,
  },
  {
    leather: {
      front1: { color: "#f3bf33" },
      front2: { color: "#f3bf33" },
      front3: { color: "#f3bf33" },
      back1: { color: "#f3bf33" },
      back2: { color: "#f3bf33" },
      back3: { color: "#f3bf33" },
    },
    pattern: {
      front1: { pattern: 1 },
      front2: { pattern: 1 },
      front3: { pattern: 1 },
      back1: { pattern: 1 },
      back2: { pattern: 1 },
      back3: { pattern: 1 },
    },
    thread: {
      color: "#192526",
    },
    logo: {
      logoId: 3,
      height: 56 * 2,
      width: 85 * 2,
      name: "Mascot",
      src: SliderLogo2,
    },
    inscription: "",
    modificationStyle: 2,
    thumbnail: InspirationDesign2,
  },
  {
    leather: {
      front1: { color: "#ed8d5d" },
      front2: { color: "#6f6550" },
      front3: { color: "#6f6550" },
      back1: { color: "#ed8d5d" },
      back2: { color: "#6f6550" },
      back3: { color: "#6f6550" },
    },
    pattern: {
      front1: { pattern: 1 },
      front2: { pattern: 1 },
      front3: { pattern: 1 },
      back1: { pattern: 1 },
      back2: { pattern: 1 },
      back3: { pattern: 1 },
    },
    thread: {
      color: "#777364",
    },
    logo: {
      logoId: 1,
      height: 52 * 2,
      width: 64 * 2,
      name: "The O",
      src: SliderLogo,
    },
    inscription: "",
    modificationStyle: 1,
    thumbnail: InspirationDesign3,
  },
  {
    leather: {
      front1: { color: "#444247" },
      front2: { color: "#444247" },
      front3: { color: "#444247" },
      back1: { color: "#444247" },
      back2: { color: "#444247" },
      back3: { color: "#444247" },
    },
    pattern: {
      front1: { pattern: 1 },
      front2: { pattern: 1 },
      front3: { pattern: 1 },
      back1: { pattern: 1 },
      back2: { pattern: 1 },
      back3: { pattern: 1 },
    },
    thread: {
      color: "#60FF00",
    },
    logo: {
      logoId: 2,
      height: 19 * 1.7,
      width: 121 * 1.7,
      name: "Ducks",
      src: SliderLogo1,
    },
    inscription: "",
    modificationStyle: 2,
    thumbnail: InspirationDesign4,
  },
];
