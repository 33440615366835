import React from "react";
const HalfWallet = React.lazy(() => import("./HalfWallet"));
const HalfWalletNew = React.lazy(() => import("./HalfWalletNew"));
const Product = (props) => {
  const model = props?.model;
  const customisationData = props?.customisationData;
  if (model === "HalfWallet") {
    return (
      // <HalfWallet
      //   customisationData={customisationData}
      //   setStepIndex={props.setStepIndex}
      //   setMainStepIndex={props.setMainStepIndex}
      //   setactiveSubStepIndex={props.setactiveSubStepIndex}
      //   setColor={props.setColor}
      // />
      <HalfWalletNew
        customisationData={customisationData}
        setStepIndex={props.setStepIndex}
        setMainStepIndex={props.setMainStepIndex}
        setactiveSubStepIndex={props.setactiveSubStepIndex}
        setColor={props.setColor}
      />
    );
  }
};

export default Product;
