import React, { Suspense, useEffect, useRef, useState } from "react";
import { Button, Container, Image } from "react-bootstrap";
import { Footer, Header, Modifications } from "../component/index";
import {
  CameraImg,
  defaultSS,
  LightWithoutCircle,
  ScissorsCircleNone,
} from "../assets/images/index";

import Product from "../products/Product";
import { newSteps, newCustomisationData } from "../data";
import { Canvas } from "react-three-fiber";
import { Environment, OrbitControls, Text } from "@react-three/drei";
import Inspiration from "../component/inspiration";

function Home() {
  const [isClass, setClass] = useState("");
  // const [customisationData, setCustomisationData] = useState(
  //   staticCustomisationData
  // );
  const [customisationData, setCustomisationData] =
    useState(newCustomisationData);
  const [maxZoomDistance, setMaxZoomDistance] = useState(0.2);
  const [stepIndex, setStepIndex] = useState(1);

  //
  const [mainStepIndex, setMainStepIndex] = useState(0);
  const [subSteps, setsubSteps] = useState(newSteps[0].subSteps);
  const [activeSubStepIndex, setactiveSubStepIndex] = useState(0);
  const [collectionData, setcollectionData] = useState();
  //
  const [screenshot, setScreenshot] = useState(defaultSS);
  const canvas = useRef(null);
  const camera = useRef(null);
  useEffect(() => {
    setsubSteps(newSteps[mainStepIndex].subSteps);
    setactiveSubStepIndex(0);
  }, [mainStepIndex]);
  useEffect(() => {
    setcollectionData(subSteps[activeSubStepIndex]?.collection_data);
  }, [activeSubStepIndex, subSteps]);

  function takeScreenshot() {
    canvas.current.toDataURL();
    const screenshot = canvas.current.toDataURL();
    setScreenshot(screenshot);
  }
  // New Functions
  function customize(params) {
    let newData;
    switch (mainStepIndex) {
      case 0:
        newData = { ...customisationData, leather: params };
        setCustomisationData(newData);
        break;
      case 1:
        newData = { ...customisationData, pattern: params };
        setCustomisationData(newData);
        break;
      case 2:
        newData = { ...customisationData, thread: params };
        setCustomisationData(newData);
        break;
      case 3:
        newData = { ...customisationData, logo: params };
        setCustomisationData(newData);
        break;
      case 4:
        newData = { ...customisationData, inscription: params };
        setCustomisationData(newData);
        break;

      default:
        break;
    }
    takeScreenshot();
  }
  function setColor(params) {
    let mainStepData = customisationData[newSteps[mainStepIndex].stepCodeName];
    let subStepData =
      mainStepData[
        newSteps[mainStepIndex].subSteps[activeSubStepIndex].subStepCodeName
      ];
    mainStepData[
      newSteps[mainStepIndex].subSteps[activeSubStepIndex].subStepCodeName
    ] = { ...subStepData, color: params };
    let newData = mainStepData;
    customize(newData);
  }
  function setThreadColor(params) {
    let mainStepData = customisationData[newSteps[mainStepIndex].stepCodeName];
    mainStepData = { ...mainStepData, color: params };
    let newData = mainStepData;
    customize(newData);
  }
  function setPattern(params) {
    let mainStepData = customisationData[newSteps[mainStepIndex].stepCodeName];
    let subStepData =
      mainStepData[
        newSteps[mainStepIndex].subSteps[activeSubStepIndex].subStepCodeName
      ];
    mainStepData[
      newSteps[mainStepIndex].subSteps[activeSubStepIndex].subStepCodeName
    ] = { ...subStepData, pattern: params };
    let newData = mainStepData;
    customize(newData);
  }
  useEffect(() => {
    if (window.innerWidth < 800) {
      setMaxZoomDistance(0.4);
    } else {
      setMaxZoomDistance(0.175);
    }
  });

  useEffect(() => {
    if (camera.current) {
      if (
        activeSubStepIndex === 0 ||
        activeSubStepIndex === 1 ||
        activeSubStepIndex === 2
      ) {
        camera.current.setAzimuthalAngle(0);
        camera.current.setPolarAngle(Math.PI / 2);
        // console.log(camera.current.getPolarAngle());
      }
      if (
        activeSubStepIndex === 3 ||
        activeSubStepIndex === 4 ||
        activeSubStepIndex === 5
      ) {
        camera.current.setAzimuthalAngle(3.14);
        camera.current.setPolarAngle(Math.PI / 2);
      }
      if (activeSubStepIndex === 0 && mainStepIndex === 4) {
        camera.current.setAzimuthalAngle(3.14);
        camera.current.setPolarAngle(Math.PI / 2);
      }
    }
  }, [activeSubStepIndex, mainStepIndex]);

  return (
    <>
      <div className="vstack h-screen">
        <Header
          customisationData={customisationData}
          setCustomisationData={setCustomisationData}
          screenshot={screenshot}
          takeScreenshot={takeScreenshot}
        />
        <section className="image-body bg-vista-white flex-fill d-flex align-items-center main-section pb-10">
          <Container fluid className="gx-10 pe-lg-0">
            <div
              className={
                isClass +
                " " +
                "body-content d-flex justify-content-lg-between justify-content-center align-items-center"
              }
            >
              <div className="left-scroll-bar">
                <div className="left-color d-none d-lg-block">
                  {mainStepIndex === 0 &&
                    collectionData &&
                    collectionData?.map((value, index) => {
                      return (
                        <button
                          className={
                            customisationData?.[
                              newSteps[mainStepIndex]?.stepCodeName
                            ][
                              newSteps[mainStepIndex]?.subSteps[
                                activeSubStepIndex
                              ]?.subStepCodeName
                            ]?.color === value?.colorHexcode
                              ? "color-round rounded-circle position-relative mb-5 p-0 animate active"
                              : "color-round rounded-circle position-relative mb-5 p-0 animate"
                          } // add "active" when selected
                          style={{ backgroundColor: value?.colorHexcode }}
                          onClick={() => setColor(value?.colorHexcode)}
                        >
                          <span className="color-name text-black text-sm font-semibold">
                            {value?.colorName}
                          </span>
                        </button>
                      );
                    })}
                  {mainStepIndex === 2 &&
                    collectionData &&
                    collectionData?.map((value, index) => {
                      return (
                        <button
                          className={
                            customisationData?.[
                              newSteps[mainStepIndex]?.stepCodeName
                            ]?.color === value?.colorHexcode
                              ? "color-round rounded-circle position-relative mb-5 p-0 animate active"
                              : "color-round rounded-circle position-relative mb-5 p-0 animate"
                          } // add "active" when selected
                          style={{ backgroundColor: value?.displayColorCode }}
                          onClick={() => setThreadColor(value?.colorHexcode)}
                        >
                          <span className="color-name text-black text-sm font-semibold">
                            {value?.colorName}
                          </span>
                        </button>
                      );
                    })}
                </div>
              </div>
              <div className="center-image text-center center-home">
                {/* <Image src={Wallet} /> */}
                <Canvas
                  ref={canvas}
                  camera={{
                    // fov: 90,
                    fov: 50,
                    position: [0, 0, 0.18],
                    near: 0.001,
                    far: 1000,
                  }}
                  gl={{ preserveDrawingBuffer: true }}
                  onClick={() => {
                    takeScreenshot();
                    // console.log(
                    //   camera,
                    //   camera.current.getPolarAngle(),
                    //   camera.current.getAzimuthalAngle()
                    //   // camera.current.setAzimuthalAngle(1.9)
                    // );
                  }}
                >
                  <OrbitControls
                    ref={camera}
                    //   ref={this.orbitControls}
                    enableZoom={true}
                    enableRotate={true}
                    maxDistance={0.3} //0.2 how much can you get far
                    minDistance={maxZoomDistance} //0.1
                    //   minDistance={0.1} //0.1
                    zoomspeed={0.5}
                  />
                  <Suspense fallback={null}>
                    <Environment
                      files={[
                        "EnvironmentHDRi/cube/pz2.png", //left
                        "EnvironmentHDRi/cube/pz2.png", // right
                        "EnvironmentHDRi/cube/py.png", // top
                        "EnvironmentHDRi/cube/ny.png", // Bottom
                        "EnvironmentHDRi/cube/pp.png", // front
                        "EnvironmentHDRi/cube/pp.png", //back
                      ]}
                      path={"/"}
                      background={false}
                    />

                    <Product
                      model={"HalfWallet"}
                      customisationData={customisationData}
                      setStepIndex={setStepIndex}
                      setMainStepIndex={setMainStepIndex}
                      setactiveSubStepIndex={setactiveSubStepIndex}
                      setColor={setColor}
                    />
                  </Suspense>
                </Canvas>
                <div className="canvas-part">
                  <div className="canvas-part-dot">
                    {subSteps &&
                      (mainStepIndex === 0 || mainStepIndex === 1) &&
                      subSteps?.map((val, ind) => {
                        if (!val?.disable) {
                          return (
                            <span
                              key={ind}
                              onClick={() => setactiveSubStepIndex(ind)}
                              className={
                                activeSubStepIndex === ind
                                  ? "active"
                                  : undefined
                              }
                            ></span>
                          );
                        }
                      })}
                    {/* <span className="active"></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span> */}
                  </div>
                </div>
              </div>
              <div
                className={`right-pattern d-none d-lg-flex flex-lg-column ${
                  mainStepIndex === 1 ? "me-6" : "gap-6 bg-linen"
                } py-6 rounded-start-3`}
              >
                {mainStepIndex === 1 ? (
                  collectionData &&
                  collectionData?.map((value, index) => {
                    return (
                      <Button
                        className={
                          customisationData?.[
                            newSteps[mainStepIndex]?.stepCodeName
                          ][
                            newSteps[mainStepIndex]?.subSteps[
                              activeSubStepIndex
                            ]?.subStepCodeName
                          ]?.pattern === value?.patternCode
                            ? "pattern-round rounded-circle position-relative lavender-grey-border animate active"
                            : "pattern-round rounded-circle position-relative lavender-grey-border animate"
                        } // add "active" when selected
                        style={{ backgroundColor: "#F5F0E7" }}
                        onClick={() => setPattern(value?.patternCode)}
                      >
                        <Image src={value?.patternThumbnail} />
                        <span className="pattern-name text-black text-sm font-semibold">
                          {value?.patternName}
                        </span>
                      </Button>
                    );
                  })
                ) : (
                  <>
                    <Inspiration
                      key={0}
                      placement={"bottom"}
                      name={
                        <span className="text-black font-regular text-base">
                          <Image src={LightWithoutCircle} />
                        </span>
                      }
                      setCustomisationData={setCustomisationData}
                    />
                    <Modifications
                      key={1}
                      placement={"bottom"}
                      name=<Image src={ScissorsCircleNone} />
                      customisationData={customisationData}
                      setCustomisationData={setCustomisationData}
                    />
                    <div
                      onClick={(e) => {
                        if (
                          camera.current.getAzimuthalAngle() > -Math.PI / 2 &&
                          camera.current.getAzimuthalAngle() < Math.PI / 2
                        ) {
                          camera.current.setAzimuthalAngle(3.14);
                          camera.current.setPolarAngle(Math.PI / 2);
                        } else {
                          camera.current.setAzimuthalAngle(0);
                          camera.current.setPolarAngle(Math.PI / 2);
                        }
                      }}
                    >
                      <Image src={CameraImg} className="cursor-pointer" />
                    </div>
                  </>
                )}
              </div>
            </div>
          </Container>
        </section>

        <div className="mt-auto bg-linen overlap-1  position-relative">
          <Container fluid className="gx-10">
            <Footer
              handleClass={(e) => {
                setClass(e);
              }}
              stepIndex={stepIndex}
              setStepIndex={setStepIndex}
              customize={customize}
              customisationData={customisationData}
              setCustomisationData={setCustomisationData}
              setColor={setColor}
              setPattern={setPattern}
              screenshot={screenshot}
              takeScreenshot={takeScreenshot}
              mainStepIndex={mainStepIndex}
              setMainStepIndex={setMainStepIndex}
              subSteps={subSteps}
            />
          </Container>
        </div>
      </div>
    </>
  );
}

export default Home;
