import React, { useState } from "react";
import { Button, Image, Modal, Row, Form, Col } from "react-bootstrap";
import { MdOutlineContentCopy } from "react-icons/md";
import { FiSend } from "react-icons/fi";
import { GrFormClose } from "react-icons/gr";
import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from "axios";

function ShareOrSaveYourDesign(props) {
  //   const [show, setShow] = useState(props.show);
  //   const handleClose = () => setShow(false);
  const [email, setEmail] = useState("");
  const [sendMailbuttontext, setSendMailbuttontext] = useState("Send Mail");

  const show = props.show;
  const handleClose = () => props.setShow(false);
  // send Email
  const sendEmail = async () => {
    if (email === "") {
      return;
    }
    var proxyUrl = "https://cors.stage.eugeniuses.com/";
    const API_KEY = process.env.SENDGRID_API_KEY;
    const fromEmail = "Woolly@cauzality.com";
    const toEmail = email;
    const subject = "Your Custom Half Wallet: https://lab.woollymade.com/VPJ7 ";

    setSendMailbuttontext("Sending Mail...");
    const response = await axios
      .post(
        proxyUrl + "https://api.sendgrid.com/v3/mail/send",
        {
          personalizations: [
            {
              to: [{ email: toEmail }],
              subject: subject,
            },
          ],
          from: { email: fromEmail },
          content: [
            {
              type: "text/html",
              value: `
            <html>
              <body>
                <h1></h1>
                <p>Hey, that’s a nice design! <br/> <br/>
                Your Custom Half Wallet: <a href="https://woolly-labs-oregonducks.stage.eugeniuses.com/VPJ7">woolly-labs-oregonducks.stage.eugeniuses.com/VPJ7 </a> <br/><br/>
                Here are 3 things you can do with this link:<br/>
                <ol>
                <li>Save it for later. The link will stay active.<br/></li>
                <li>Send it to a friend. These make great gifts 😊<br/></li>
                <li>Post it on social…and mention @woollymade – we’d love to see your design!<br/></li>
                </ol>
                <br/>
                --<br/><br/>
                Woolly Made<br/>
                2211 NW Front Ave, #101 <br/>
                Portland, OR 97209 <a href="https://woollymade.com/">woollymade.com</a> / <a href="https://www.instagram.com/woollymade/">@woollymade</a>
                </p>
              </body>
            </html>
          `,
            },
          ],
        },
        {
          headers: {
            Authorization: `Bearer ${API_KEY}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        console.log(response.status);
        setSendMailbuttontext("Mail sent");
      })
      .catch((error) => {
        console.log(error);
        setSendMailbuttontext("Mail not sent!");
      });

    // console.log(response.status);
    // console.log(response.data);
  };
  return (
    <>
      <Modal
        {...props}
        size="xl"
        aria-labelledby="ShareOrSaveYourDesign"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className="border-0 pe-0 bg-rose-white">
          <Button
            variant="transparent"
            className="shadow-none border-0 p-0"
            onClick={handleClose}
          >
            {" "}
            <GrFormClose className="text-2xl" />{" "}
            <span className="text-black">Close</span>{" "}
          </Button>
        </Modal.Header>
        <Modal.Title
          id="ShareOrSaveYourDesign"
          className="text-center w-full bg-rose-white"
        >
          <h3 className="text-uppercase text-hochelaga">
            Share or SAVE Your Design
          </h3>
        </Modal.Title>
        <Modal.Body className="py-sm-12 px-sm-20 bg-rose-white">
          <div className="text-center mb-10">
            {/* <Image src={props.screenshot} className="w-48" /> */}
            <div className="img-box">
              {/* <Image src={props.screenshot} className="img-cover margin-80" /> */}
              <Image src={props.screenshot} className="img-cover" />
            </div>
          </div>
          <div className="copy-email-link-sec">
            <Form className="text-center">
              <Row>
                <Col lg={6}>
                  <Form.Group className="mb-5" controlId="formBasicEmail">
                    <Form.Label className="text-lg font-bold">
                      Link to your design:
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      className="text-lg shadow-none border-0 rounded-0 text-black text-center input-border-bottom bg-transparent"
                      type="text"
                      placeholder="https://customizer.woollymade.com/VPJ7"
                      value={"https://customizer.woollymade.com/VPJ7"}
                    />
                  </Form.Group>
                  <CopyToClipboard
                    text={`https://customizer.woollymade.com/VPJ9`}
                  >
                    <Button
                      variant="green"
                      className="rounded-6 px-sm-24 px-18 d-inline-flex align-items-center gap-3"
                    >
                      <MdOutlineContentCopy className="text-xl" /> Copy Link
                    </Button>
                  </CopyToClipboard>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-5" controlId="formBasicEmail">
                    <Form.Label className="text-lg font-bold">
                      Email your design:
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      className="text-lg shadow-none border-0 rounded-0 text-black text-center input-border-bottom bg-transparent"
                      type="email"
                      placeholder="Enter email address..."
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setSendMailbuttontext("Send Mail");
                      }}
                    />
                  </Form.Group>
                  <Button
                    variant="green"
                    className="rounded-6 px-sm-24 px-18 d-inline-flex align-items-center gap-3"
                    disabled={sendMailbuttontext === "Mail sent" ? true : false}
                    onClick={() => {
                      sendEmail();
                    }}
                  >
                    <FiSend className="text-xl" /> {sendMailbuttontext}
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ShareOrSaveYourDesign;
