import React, { useState } from "react";
import { Button, Offcanvas, Row, Col, Image, Container } from "react-bootstrap";
import { LightWithoutCircle, Light } from "../assets/images";
import { inspirationDesigns, newinspirationDesigns } from "../data";
import { LoadDesign } from "./modal";

function Inspiration({
  name,
  // customisationData,
  setCustomisationData,
  ...props
}) {
  const [show, setShow] = useState(false);
  const [loadDesignModelShow, setLoadDesignModelShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // function loadInspirationDesign() {
  //   setCustomisationData(inspirationDesigns[inspirationDesignsIndex]);
  //   handleClose();
  // }
  function loadInspirationDesign(index) {
    setCustomisationData(newinspirationDesigns[index]);
    handleClose();
  }

  return (
    <>
      <Button
        variant="transparent"
        onClick={handleShow}
        className="border-0 shadow-none p-0"
      >
        {name}
        {/* <Image src={Light} className="d-none d-lg-inline-block" /> */}
      </Button>
      <Offcanvas
        show={show}
        onHide={handleClose}
        {...props}
        className="bg-vista-white modification-offcanvas"
      >
        <Container fluid>
          <Offcanvas.Header className="justify-content-end">
            <Button
              variant="transparent"
              className="shadow-none border-0 p-0"
              onClick={handleClose}
            >
              <span className="text-black">Inspiration</span>{" "}
              <Image src={LightWithoutCircle} />
            </Button>
          </Offcanvas.Header>
          <Offcanvas.Body className="inspiration-content scroll-hide py-0 pt-14">
            <Row className="gy-5">
              {newinspirationDesigns.map((value, index) => {
                return (
                  <Col
                    md={6}
                    lg={4}
                    xl={3}
                    className="text-center mt-0 mb-5"
                    key={index}
                  >
                    <div className="inspiration-col position-relative">
                      <div className="save-img">
                        <Image
                          src={value.thumbnail}
                          className="h-64 w-full"
                          role="button"
                        />
                      </div>
                      <div className="inspiration-button">
                        <Button
                          variant="outline-midnight-blue"
                          onClick={() => {
                            // setInspirationDesignsIndex(index);
                            // setLoadDesignModelShow(true);
                            loadInspirationDesign(index);
                          }}
                        >
                          Load Design
                        </Button>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Offcanvas.Body>
        </Container>
      </Offcanvas>
      <LoadDesign
        show={loadDesignModelShow}
        setShow={setLoadDesignModelShow}
        loadInspirationDesign={loadInspirationDesign}
      />
    </>
  );
}

export default Inspiration;
